

import { CF2Component, registerComponent } from 'javascript/lander/runtime'

export default class CourseSidebarV3 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

mount () {
    const input = this.element.querySelector('input.elCourseSidebarHamburgerInput')
    this.handleHamburgerIconValue(input)
    input?.addEventListener('click',(evt) => {
      this.handleHamburgerIconValue(input)
    })
    this.element.querySelectorAll('.fa[data-type="withChildren"]').forEach((courseSidebarItemIcon) => {
      courseSidebarItemIcon.addEventListener('click', (e) => {
        const icon = e.target
        const wrapper = icon.parentNode.parentNode.querySelector('.elCourseSidebarItemSection')
        this.handleModuleSidebarToggle(wrapper, icon)
      })
    })
    this.element.querySelectorAll('.fa[data-type="withoutChildren"]').forEach((courseSidebarItemIcon) => {
      courseSidebarItemIcon.addEventListener('click', (e) => {
        const icon = e.target
        const anchor = icon.parentNode.querySelector('a')
        anchor.click()
      })
    })
    this.element.querySelectorAll('.elCourseSidebarItem').forEach((item) => {
      item.addEventListener('mouseenter', () => {
        const link = item.querySelector('a')
        link.style['white-space'] = 'normal'
        link.style['text-overflow'] = 'normal'
      })
      item.addEventListener('mouseleave', () => {
        const link = item.querySelector('a')
        link.style['white-space'] = 'nowrap'
        link.style['text-overflow'] = 'ellipsis'
      })
    })

    // Tabs Navigation
    const tabsScroller = this.element.querySelector('.elCourseSidebarTabsScroller')
    if (tabsScroller) {
      const leftButton = this.element.querySelector('.elCourseSidebarTabsScrollButtonLeft')
      const rightButton = this.element.querySelector('.elCourseSidebarTabsScrollButtonRight')
      const scrollButtons = this.element.querySelectorAll('.elCourseSidebarTabsScrollButton')
      const tabItems = [...this.element.querySelectorAll('.elCourseSidebarTabsItem')]
      const activeTabItem = this.element.querySelector('.elCourseSidebarTabsItem[data-selected="true"]')

      this.scrollToTab(activeTabItem, tabsScroller)
      this.updateButtonsDisplay(tabsScroller, leftButton, rightButton)

      tabsScroller.addEventListener('scroll', (evt) => {
        this.updateButtonsDisplay(tabsScroller, leftButton, rightButton)
      })

      scrollButtons.forEach((button, index) => {
        button.addEventListener('click', (evt) => {
          let centeredTabIndex

          // Get exactly centered element if there is one
          centeredTabIndex = tabItems.findIndex((tab, index) => this.isTabCentered(tab, tabsScroller))

          if(button === leftButton){
            if(centeredTabIndex != -1){
              centeredTabIndex -= 1
            } else {
              // Select first tab in scroll viewport
              centeredTabIndex = tabItems.findIndex((tab, index) => this.isTabInViewport(tab, tabsScroller))
            }
          }

          if(button === rightButton){
            if(centeredTabIndex != -1){
              centeredTabIndex += 1
            } else {
              // Select last tab in scroll viewport
              centeredTabIndex = tabItems.findLastIndex((tab, index) => this.isTabInViewport(tab, tabsScroller))
            }
          }

          this.scrollToTab(tabItems[centeredTabIndex], tabsScroller)
          this.updateButtonsDisplay(tabsScroller, leftButton, rightButton)
        })
      })
    }

  }

  handleHamburgerIconValue(input) {
    if (!input) return
    if (input.checked) {
      this.element.querySelector('.elCourseSidebar').classList.add('elCourseSidebarOpen')
    } else {
      this.element.querySelector('.elCourseSidebar').classList.remove('elCourseSidebarOpen')
    }
  }

  handleModuleSidebarToggle(wrapper, icon) {
    wrapper.dataset.expanded = wrapper.dataset.expanded == 'true' ? 'false' : 'true'
    const iconExpanded = 'fa-angle-down'
    const iconRegular = 'fa-angle-down'
    if (icon.classList.contains(iconExpanded)) {
      icon.classList.remove(iconExpanded)
      icon.classList.add(iconRegular)
    } else {
      icon.classList.remove(iconRegular)
      icon.classList.add(iconExpanded)
    }
  }

  scrollToTab(tab, tabsScroller){
    let centeredOffset = this.getCenteredOffset(tab, tabsScroller)

    if(tab.offsetWidth >= tabsScroller.offsetWidth) centeredOffset = 0

    tabsScroller.scrollTo({
      left: tab.offsetLeft - centeredOffset,
      behavior: 'smooth',
    });
  }

  getCenteredOffset(tab, tabsScroller){
    return (tabsScroller.offsetWidth - tab.offsetWidth)/2
  }

  isTabInViewport(tab, tabsScroller){
    const scrollerWidth = tabsScroller.offsetWidth
    const currentScrollLeft = tabsScroller.scrollLeft;
    const currentScrollRight = currentScrollLeft + scrollerWidth

    const tabWidth = tab.offsetWidth
    const tabLeft = tab.offsetLeft
    const tabRight = tabLeft + tabWidth

    return (
      (tabLeft <= currentScrollRight && tabRight >= currentScrollRight) ||
      (tabLeft <= currentScrollLeft && tabRight >= currentScrollLeft)
    )
  }

  isTabCentered(tab, tabsScroller){
    const currentScrollLeft = tabsScroller.scrollLeft;
    let centeredOffset = this.getCenteredOffset(tab, tabsScroller)
    centeredOffset = centeredOffset > 0 ? centeredOffset : 0

    return Math.round(tab.offsetLeft - centeredOffset) == currentScrollLeft
  }

  updateButtonsDisplay(tabsScroller, leftButton, rightButton){
    leftButton.style.display = tabsScroller.scrollLeft == 0 ? 'none' : 'flex'
    rightButton.style.display = tabsScroller.scrollLeft + tabsScroller.offsetWidth == tabsScroller.scrollWidth ? 'none' : 'flex'
  }



}

registerComponent('CourseSidebar/V3', CourseSidebarV3)
window["CourseSidebarV3"] = CourseSidebarV3

